import {ChangeDetectorRef, Component, EventEmitter, ViewChild} from '@angular/core';
import {
    BodyScrollEvent,
    CellClickedEvent,
    ColDef,
    ColGroupDef, ColumnVisibleEvent,
    GetContextMenuItemsParams,
    GridApi, GridColumnsChangedEvent,
    GridOptions,
    GridReadyEvent,
    RowNode
} from 'ag-grid-community';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import {ToastrService} from 'ngx-toastr';
import {interval, Observable, Subject} from 'rxjs';
import {debounce, debounceTime, filter, takeUntil} from 'rxjs/operators';
import {LastQuoteCacheService} from '../last-quote-cache.service';
import {MessageBusService} from '../message-bus.service';
import {OptionsChainService} from '../option-chains.service';
import {QuoteDto} from '../shell-communication/dtos/quote-dto.class';
import {TradingInstrument} from '../trading-instruments/trading-instrument.class';
import {
    deepCloneObject,
    DetectMethodChanges,
    DxValueChanged,
    findAtmStrikeIndex,
    getShortUUID,
    isCashSettledOptionTicker,
    isNullOrUndefined,
    isOptionExpired,
    isValidNumber,
    isVoid,
    wrapInPromise
} from '../utils';
import {getGridColumnDefinitions, getOptionsPricingGridModel} from './options-pricing-grid-options';
import {PricingGridStrategy} from './model/pricing-grid.strategy';
import {makeOptionDisplayName, OptionType, parseOptionTicker} from '../options-common/options.model';
import {ComboHighlightedItem, ComboHighlightedUIMessage, SymbolHighlighted} from '../ui-messages/ui-messages';
import {TimeInForce} from '../trading-model/time-in-force.enum';
import {OrderType} from '../trading-model/order-type.enum';
import {ClipboardService} from '../clipboard.service';
import {MarketSide} from '../trading-model/market-side.enum';
import {
    GetOptionChainShellResponse,
    GreeksDto,
    OptionExpirationDescriptor,
    ResubscribeOptionStrategiesDto,
    StrategyPriceDto
} from '../shell-communication/shell-dto-protocol';
import {TradingInstrumentsService} from '../trading-instruments/trading-instruments-service.interface';
import {SymbolPickerComponent} from '../symbol-picker/symbol-picker.component';
import {PortfolioItemType} from '../portfolios/portfolios.model';
import {PanelBaseComponent} from '../panels/panel-base.component';
import {OpgTemplate, OptionPricingGridTemplatesService} from "./option-pricing-grid-templates.service";
import {OpgEditTemplatePopupModel} from "./OpgEditTemplatePopupModel";
import {DateTime, Duration} from "luxon";
import {AccessControlService} from "../access-control-service.class";
import {GenericConfirmationDialogComponent} from "../generic-confirmation-dialog/generic-confirmation-dialog.component";
import {PricingGridLegDescriptor} from "./model/pricing-grid-leg.descriptor";
import {GridSettingsProvider} from "./model/grid-settings.provider";
import {PricingGridStrategyColumn} from "./model/pricing-grid-strategy.column";
import {PricingGridRow} from "./model/pricing-grid.row";
import {PricingGridStrategyDescriptor} from "./model/pricing-grid-strategy.descriptor";
import {ServiceConfiguration} from "../adjustment-pricing-grid/services/ServiceConfiguration";
import {SessionService} from "../authentication/session-service.service";
import {CashFlowPortfoliosService} from "../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {UserDto} from "../authentication/dtos/auth-result-dto.inteface";
import {ApgPortfolio} from "../adjustment-pricing-grid/model/ApgPortfolio";
import * as Enumerable from "linq";
import {HedgePositionsService} from "../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {HedgePosition} from "../hedging-grid/data-model/hedge-position";
import {CustomHedgeLeg} from "./model/custom-hedge-leg";
import {HedgesPricingService} from "../package-comparison/services/hedges-pricing.service";
import {OrdersVerificationDialogComponent} from "./orders-verification-dialog/orders-verification-dialog.component";
import {OrdersVerificationDialogConfig} from "./orders-verification-dialog/orders-verification-dialog.config";
import {UserSettingsService} from "../user-settings.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";
import {PricingGridLeg} from "./model/pricing-grid.leg";

type OpgStateKey = 'opg.symbol' | 'opg.expiration' | 'opg.step' | 'opg.num-of-strikes' | 'opg.center-strike'
    | 'opg.call-range-start' | 'opg.call-range-end' | 'opg.put-range-start' | 'opg.put-range-end'
    | 'opg.call-offset' | 'opg.call-range' | 'opg.put-offset' | 'opg.put-range' | 'opg.is-custom-range'
    | 'opg.default-qty.own' | 'opg.default-qty.custom';

interface CustomColumnDialogConfig {
    visible?: boolean;
    header?: string;
    spreadWidth?: number;
}

interface PanelState {
    isLinkedToSymbol: boolean;
}

export type OptionPricingGridMode = 'opg' | 'pmd';

@Component({
    selector: 'grid-base',
    template: ''
})
export abstract class OptionsPricingGridComponentBase extends PanelBaseComponent implements GridSettingsProvider {
    protected constructor(
        protected readonly _changeDetector: ChangeDetectorRef,
        protected readonly _userSettingsService: UserSettingsService,
        protected readonly _messageBus: MessageBusService,
        private _lastQuoteCache: LastQuoteCacheService,
        private _optionsChainService: OptionsChainService,
        private _toastr: ToastrService,
        private _clipboardService: ClipboardService,
        private _tiService: TradingInstrumentsService,
        private _templatesService: OptionPricingGridTemplatesService,
        public readonly accessControlService: AccessControlService,
        private readonly _sessionService: SessionService,
        private readonly _portfolioService: CashFlowPortfoliosService,
        private readonly _apgDataService: ApgDataService,
        private readonly _hedgePositionService: HedgePositionsService
    ) {
        super(_changeDetector, _userSettingsService, _messageBus);

        this.editTemplatePopupModel = new OpgEditTemplatePopupModel(_changeDetector, _toastr);

        this._hedgesPricingService = new HedgesPricingService(
            _hedgePositionService,
            _lastQuoteCache,
            _messageBus,
            _apgDataService
        );
    }

    private _unsubscriber = new Subject();
    private _grid: GridReadyEvent;
    private _rowsByStrategyCode: Record<string, PricingGridRow> = {};
    private readonly _hedgesPricingService: HedgesPricingService;

    abstract mode: OptionPricingGridMode;

    @ViewChild(SymbolPickerComponent) symbolPicker: SymbolPickerComponent;

    @ViewChild(GenericConfirmationDialogComponent) confirmationDialogCmp: GenericConfirmationDialogComponent;

    get toastr(): ToastrService {
        return this._toastr;
    }

    optionChain: GetOptionChainShellResponse;

    autoSizeColumns = true;

    callsLiveMode = false;

    putsLiveMode = false;

    strategiesList = new DataSource({
        store: new ArrayStore({
            key: 'strategy',
            data: [
                {strategy: '1 Leg', category: 'Raw'},
                {strategy: '2 Legs', category: 'Raw'},
                {strategy: '3 Legs', category: 'Raw'},
                {strategy: '4 Legs', category: 'Raw'},
                {strategy: '6 Legs', category: 'Raw'},
                {strategy: '8 Legs', category: 'Raw'},
                {strategy: 'BOS', category: 'Templates'},
                {strategy: 'Butterfly', category: 'Templates'},
                {strategy: 'Condor', category: 'Templates'},
                {strategy: 'Slingshot', category: 'Templates'},
                {strategy: 'Slingshot - Modified', category: 'Templates'},
                {strategy: 'Sponsored Long', category: 'Templates'},
                {strategy: 'Vertical', category: 'Templates'},
            ]
        }),
        group: 'category'
    });

    selectedCell: CellClickedEvent;

    customColumnDialogConfig: CustomColumnDialogConfig = {};

    lastPx: number;

    numOfStrikes: number;

    strikeStep: number;

    centerStrike: number;

    tradingInstrument: TradingInstrument;

    gridOptions: GridOptions;

    pricingRows: PricingGridRow[] = [];

    selectedExpiration: OptionExpirationDescriptor;

    percentageProfit: number;

    availableExpirations: OptionExpirationDescriptor[] = [];

    isCustomRange = false;

    callOffset: number;

    callRange: number;

    callsTargetRangeStart: number;

    callsTargetRangeEnd: number;

    callsPropagateRange = false;

    putsTargetRangeStart: number;

    putsTargetRangeEnd: number;

    putOffset: number;

    putRange: number;

    putsPropagateRange = false;

    rMultiple: number;

    callStrategies: PricingGridStrategy[] = [];

    putStrategies: PricingGridStrategy[] = [];

    showItmData = false;

    gridMode: 'Single' | 'Comparison' = 'Comparison';

    callsSelectedTemplate: OpgTemplate;

    putsSelectedTemplate: OpgTemplate;

    editTemplatePopupModel: OpgEditTemplatePopupModel;

    putsSidePanelVisible = true;

    callsSidePanelVisible = true;

    portfolioList: { key: string, userId: string, items: ApgPortfolio[] }[];

    selectedCallsPortfolio: ApgPortfolio;

    selectedPutsPortfolio: ApgPortfolio;

    callHedgesList: { id: string, label: string, items: HedgePosition[] }[];

    selectedCallHedge: { id: string, label: string, items: HedgePosition[] };

    putHedgesList: { id: string, label: string, items: HedgePosition[] }[];

    selectedPutHedge: { id: string, label: string, items: HedgePosition[] };

    defaultQty: number = 1;

    @ViewChild(OrdersVerificationDialogComponent)
    orderVerificationDialog: OrdersVerificationDialogComponent;

    get expiration(): OptionExpirationDescriptor {
        return this.selectedExpiration;
    }


    get atmStrike(): number {
        return this.centerStrike;
    }


    get lastQuoteCache(): LastQuoteCacheService {
        return this._lastQuoteCache;
    }


    get gridApi(): GridApi {
        return this._grid.api;
    }


    get subControlsEnabled(): boolean {
        return !!this.tradingInstrument && !!this.selectedExpiration;
    }


    etsOnInit() {

        this.gridOptions = getOptionsPricingGridModel.bind(this)();

        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onQuote(x.payload));


        this._messageBus.of<GreeksDto>('GreeksDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onGreeks(x.payload));

        this._messageBus.of<StrategyPriceDto[]>('StrategyPriceDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onStrategyPriceDto(x.payload));

        this._messageBus
            .of<SymbolHighlighted>('SymbolHighlighted')
            .pipe(
                filter(x => this.isLinkedToSymbol),
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onSymbolHighlightedMessage(x.payload));

        this._messageBus
            .of<ResubscribeOptionStrategiesDto>('ResubscribeOptionStrategiesDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onResubscribeOptionStrategies(x.payload));


        this._messageBus.of<{ portfolios: ApgPortfolio[] }>('Apg.PortfoliosUpdated')
            .subscribe((msg) => this.onPortfoliosUpdated(msg.payload));

        this.scrollEvent.pipe(
            debounce(_ => interval(250))
        ).subscribe(x => this.onGridScrolled(x))

        this.initPortfolioService()
            .then(() => {
                this._hedgesPricingService.init();
                this._hedgesPricingService.hedgePriceChanged$
                    .subscribe(() => this._changeDetector.detectChanges())
                this.restoreState();
            })
            .finally(() => {
                this._changeDetector.detectChanges();
            });
    }


    etsAfterViewInit() {
    }


    @DetectMethodChanges()
    setLiveStatus(targetSide: 'calls' | 'puts', $event: boolean) {
        if (targetSide === 'calls') {
            this.callsLiveMode = $event || false;
        } else if (targetSide === 'puts') {
            this.putsLiveMode = $event || false;
        }
    }

    getLiveStatus(targetSide: 'calls' | 'puts') {
        if (targetSide === 'calls') return this.callsLiveMode;
        if (targetSide === 'puts') return this.putsLiveMode;
    }

    private onResubscribeOptionStrategies(x: ResubscribeOptionStrategiesDto): void {
        const codesToSubscribe = this.getSubscribedStrategyCodes('sub');
        this.changeOptionStrategiesSubscription([], codesToSubscribe);
    }

    getTemplates(targetSide: 'calls' | 'puts') {
        if (this.isWrongSide(targetSide)) {
            return undefined;
        }

        return targetSide === 'calls'
            ? this._templatesService.getCallTemplates()
            : this._templatesService.getPutTemplates();
    }


    @DetectMethodChanges({isAsync: true})
    async onSymbolHighlightedMessage(x: SymbolHighlighted): Promise<void> {
        const ti = this._tiService.getInstrumentByTicker(x.ticker);
        if (!ti) {
            return;
        }

        try {
            await this.onSymbolSelected(ti);
            this.symbolPicker.selectedInstrument = ti;
        } catch {
            //
        }
    }


    etsOnDestroy(): void {
        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }
        this._hedgesPricingService.dispose();
        const codesTounsubscribe = this.getSubscribedStrategyCodes('unsub');
        this.changeOptionStrategiesSubscription(codesTounsubscribe, []);
        this.unsubscribeCurrentTickers();
    }


    @DetectMethodChanges({isAsync: true})
    async onSymbolSelected(ti: TradingInstrument): Promise<void> {

        this.isLoading = true;

        this.centerStrike = null;
        this.selectedExpiration = null;
        this.strikeStep = null;
        this.numOfStrikes = null;
        this.optionChain = await this._optionsChainService.getChain(ti.ticker);

        try {
            await this.setNewSymbol(ti);

        } finally {
            this.isLoading = false;
        }

        if (!isVoid(ti)) {
            this.saveOpgState('opg.symbol', ti.ticker);
            await this.applyOpgState(ti);
        }
    }

    async onTemplateSelected(targetSide: 'calls' | 'puts', ev: DxValueChanged<OpgTemplate>) {
        try {
            await this.onTemplateSelectedInternal(targetSide, ev);
        } finally {
            this.scrollToTargetSide(targetSide);
        }
    }

    private async onTemplateSelectedInternal(targetSide: 'calls' | 'puts', ev: DxValueChanged<OpgTemplate>) {

        if (this.isWrongSide(targetSide)) {
            return;
        }

        if (isVoid(ev.event)) {
            return;
        }

        this.isLoading = true;

        try {

            await wrapInPromise(async () => {

                let strategies = targetSide === 'calls' ? this.callStrategies : this.putStrategies;
                strategies.forEach(x => this.removeStrategy(targetSide, x));

                if (targetSide === 'calls') {
                    this.callsSelectedTemplate = ev.value;
                } else {
                    this.putsSelectedTemplate = ev.value;
                }

                const selectedTemplate = targetSide === 'calls' ? this.callsSelectedTemplate : this.putsSelectedTemplate;

                if (isVoid(selectedTemplate)) {
                    return;
                }

                selectedTemplate.descriptors.forEach(d => {
                    this.addStrategy(targetSide, d);
                });
            });
        } finally {
            this.isLoading = false;
        }

        if (!this.canApplyAllStrategies(targetSide)) {
            return;
        }

        let shouldApplyAllStrategies: boolean;

        if (targetSide === 'calls') shouldApplyAllStrategies = this.callsLiveMode;
        if (targetSide === 'puts') shouldApplyAllStrategies = this.putsLiveMode;

        if (!shouldApplyAllStrategies) return;

        this.applyAllStrategies(targetSide);
    }

    @DetectMethodChanges()
    addStrategy(targetSide: 'calls' | 'puts', descriptor?: PricingGridStrategyDescriptor): Promise<void> {

        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return Promise.resolve();
        }

        const container = targetSide === 'calls' ? this.callStrategies : this.putStrategies;

        const strategy = new PricingGridStrategy(
            this,
            targetSide,
            descriptor
        );

        if (descriptor) {
            strategy.strategyName = descriptor.strategyName;
            strategy.selectedStrategy = descriptor.selectedStrategy;
            strategy.strategyLegs = deepCloneObject(descriptor.strategyLegs);
            strategy.width = descriptor.width;
            strategy.markForChanges();
        }

        let nameFound = false;
        let attempt = container.length;

        do {

            attempt += 1;
            let strategyName = strategy.strategyName;

            if (isVoid(strategyName)) {
                strategyName = `Strategy #${attempt}`;
            }

            const ix = container.findIndex(x => x.strategyName === strategyName);

            nameFound = ix === -1;

            if (nameFound) {
                strategy.strategyName = strategyName;
            }

            if (attempt > 1000) {
                break;
            }
        } while (!nameFound);


        const subscription1 = strategy.applyLegsClicked$
            .subscribe((ev) => {
                try {
                    this.onStrategyApplyClicked(targetSide, ev);
                } finally {
                    this.scrollToTargetSide(targetSide);
                }
            });

        const subscription2 = strategy.somethingChanged$
            .subscribe((ev) => this.onStrategySomethingChanged(ev));

        const subscription3 = strategy.callDetectChanges$
            .subscribe((ev) => this._changeDetector.detectChanges());

        strategy.unsubscribers.push(subscription1, subscription2, subscription3);

        container.push(strategy);

        this.gridMode = 'Comparison';

        this._grid.api.setColumnDefs([]);

        const defs = getGridColumnDefinitions(this);

        this._grid.api.setColumnDefs(defs);

        this.sizeColumns();
    }

    private isWrongSide(targetSide: 'calls' | 'puts') {
        return targetSide !== 'calls' && targetSide !== 'puts';
    }

    @DetectMethodChanges({delay: 25})
    removeStrategy(targetSide: 'calls' | 'puts', str: PricingGridStrategy) {

        if (this.isWrongSide(targetSide)) {
            return;
        }

        const container = targetSide === 'calls' ? this.callStrategies : this.putStrategies;

        const ix = container.indexOf(str);

        if (ix === -1) {
            return;
        }

        str.unsubscribers.forEach(x => x.unsubscribe());

        container.splice(ix, 1);

        const defs = getGridColumnDefinitions(this);

        this.isLoading = true;

        this._grid.api.setColumnDefs([]);

        const codesToUnsubscribe = [];

        setTimeout(() => {
            try {

                this._grid.api.setColumnDefs(defs);

                this.sizeColumns();

                if (this.pricingRows.length > 0) {
                    this.pricingRows.forEach(x => {
                        const removedColumns = x.removeStrategyColumn(str, 'comparison');
                        removedColumns
                            .filter(col => !!col.strategyCode)
                            .forEach(col => {
                                codesToUnsubscribe.push(col.strategyCode);
                            });
                    });
                }


                this.changeOptionStrategiesSubscription(codesToUnsubscribe, []);

                this.pricingRows
                    .forEach(x => x.resetStrategyLegHighlights(targetSide, str))

            } finally {
                this.scrollToTargetSide(targetSide);
                this.isLoading = false;
            }
        }, 0);
    }

    @DetectMethodChanges()
    onStrategySomethingChanged(ev: PricingGridStrategy) {
        if (this._grid) {
            this._grid.api.refreshHeader();
            this.applyLiveIfPossible(ev.targetSide);
        }
    }

    @DetectMethodChanges({isAsync: true})
    async onExpirationSelected(ev: { value: OptionExpirationDescriptor }): Promise<void> {

        if (!isVoid(ev.value)) {
            this.saveOpgState('opg.expiration', ev.value.optionExpirationDate);
        }

        if (this.pricingRows.length === 0) {
            if (this.pricingRows.length === 0) {
                if (this.readyToFillPricingRows()) {
                    await this.fillPricingRows();
                }
            }

            return;
        }

        this.isLoading = true;

        await wrapInPromise(() => {
            try {

                const validationContext = {
                    expiration: ev.value,
                    side: 'Calls & Puts'
                };

                const offsetsGood = this.validateOffsetsForStrategies(validationContext);

                if (!offsetsGood) {
                    this._toastr.error('Negative offset is referencing non-existing expiration');
                    return;
                }

                this._rowsByStrategyCode = {};

                const alreadySubscribedTickers = this.getSubscribedTickers();
                const alreadySubscribedCodes = this.getSubscribedStrategyCodes('unsub');

                this.pricingRows.forEach(pr => {
                    pr.onExpirationChanged();
                });

                this._grid.api.applyTransactionAsync({update: this.pricingRows});

                const toSubscribeTickers = this.getSubscribedTickers();
                const toSubscribeCodes = this.getSubscribedStrategyCodes('sub');

                this._lastQuoteCache.subscribeTickersDiff(alreadySubscribedTickers, toSubscribeTickers);
                this.changeOptionStrategiesSubscription(alreadySubscribedCodes, toSubscribeCodes);

            } finally {

                this.isLoading = false;

            }
        });
    }

    onGridReady(args: GridReadyEvent) {
        this._grid = args;
        this._grid.api.sizeColumnsToFit();

        this.applyOpgState().then(() => {
        });

    }

    onCellClicked(args: CellClickedEvent): void {

        this.isLoading = true;

        setTimeout(() => {

            try {

                const fieldCode = args.colDef.field;

                const isPricingColumn = fieldCode.startsWith('pricing-');

                let isHidingCell = true;

                if (fieldCode.indexOf('-call') > 0) {

                    const isOtm = isNullOrUndefined((args.data as PricingGridRow).callOtm);
                    isHidingCell = isOtm && !this.showItmData;

                } else if (fieldCode.indexOf('-put') > 0) {

                    const isOtm = isNullOrUndefined((args.data as PricingGridRow).putOtm);
                    isHidingCell = isOtm && !this.showItmData;

                }

                if (!isPricingColumn || isHidingCell) {

                    this.selectedCell = null;

                } else {

                    this.selectedCell = args;

                }


                this.pricingRows.forEach(row => row.resetCellHighlights());

                if (this.selectedCell) {
                    const theCell = this.selectedCell;

                    const rowStrike = (theCell.data as PricingGridRow).rowStrike;

                    const baseRow = this.pricingRows.find(row => row.rowStrike === rowStrike);

                    if (baseRow) {
                        baseRow.highlightStrategyLegs();
                    }
                }

            } finally {

                this._grid.api.refreshCells({force: true});

                this.isLoading = false;

            }
        });

    }

    private async onCopyToEts(args: GetContextMenuItemsParams) {
        const fieldCode = args.column.getColDef().field;

        if (fieldCode.startsWith('pricing-')) {

            this.copyEtsComboToClipboard(args);

        } else if (fieldCode === 'putPrice' || fieldCode === 'callPrice') {

            const gridRow = args.node.data as PricingGridRow;

            const isCall = fieldCode === 'callPrice';

            const isCombo = (isValidNumber(gridRow.putPriceQty, true) && !isCall)
                || (isValidNumber(gridRow.callPriceQty, true) && isCall);

            const colId = args.column.getColId();
            const is3col = colId.startsWith('trans') || colId.startsWith('own') || colId.startsWith('out');

            if (isCombo && !is3col) {
                this.copyEtsComboToClipboard(args);
            } else {
                this.copyEtsOptionContractToClipboard(args)
            }
        }
    }

    private async copyComboToHedgingGrid(args: GetContextMenuItemsParams) {
        const fieldCode = args.column.getColDef().field;

        if (fieldCode.startsWith('pricing-')) {

            this.copyEtsComboToClipboard(args, 'hg');

        } else if (fieldCode === 'putPrice' || fieldCode === 'callPrice') {

            const gridRow = args.node.data as PricingGridRow;

            const isCall = fieldCode === 'callPrice';

            const isCombo = (isValidNumber(gridRow.putPriceQty, true) && !isCall)
                || (isValidNumber(gridRow.callPriceQty, true) && isCall);

            if (isCombo) {
                this.copyEtsComboToClipboard(args, 'hg');
            } else {
                this.copyEtsOptionContractToClipboard(args, 'hg');
            }
        }
    }


    private async copyComboToTOS(args: GetContextMenuItemsParams) {

        const fieldCode = args.column.getColDef().field;

        if (fieldCode.startsWith('pricing-')) {

            await this.copyTOSComboToClipboard(args);

        } else if (fieldCode === 'putPrice' || fieldCode === 'callPrice') {

            const gridRow = args.node.data as PricingGridRow;

            const isCall = fieldCode === 'callPrice';

            const isCombo = (isValidNumber(gridRow.putPriceQty, true) && !isCall)
                || (isValidNumber(gridRow.callPriceQty, true) && isCall);

            if (isCombo) {
                await this.copyTOSComboToClipboard(args);
            } else {
                await this.copyTOSOptionContractToClipboard(args);
            }
        }
    }


    async onStrikesNumberChanged(ev): Promise<void> {
        if (!ev.event) {
            return;
        }

        this.saveOpgState('opg.num-of-strikes', ev.value);

        await this.fillPricingRows();
    }


    async onStrikeStepChanged(ev): Promise<void> {
        if (!ev.event) {
            return;
        }

        this.saveOpgState('opg.step', ev.value);

        await this.fillPricingRows();
    }


    async onAtmStrikeChanged(ev): Promise<void> {
        this.centerStrike = ev.value;
        this.saveOpgState('opg.center-strike', this.centerStrike);
        await this.fillPricingRows();
    }


    onShowItmDataChanged(ev) {

        this.isLoading = true;

        setTimeout(() => {

            try {

                if (!this.showItmData) {
                    if (this.selectedCell) {
                        if (this.isItmCell(this.selectedCell)) {
                            this.selectedCell = null;
                        }
                    }
                }

                // const itmStrategyCodes = this.getItmStrategyCodes();

                // if (this.showItmData) {
                //     this.changeOptionStrategiesSubscription([], itmStrategyCodes);
                //     console.log('subscribing itm data', itmStrategyCodes.length);
                // } else {
                //     this.changeOptionStrategiesSubscription(itmStrategyCodes, []);
                //     console.log('un-subscribing itm data', itmStrategyCodes.length);
                // }

                this.makeHighlights().then(() => {
                });


            } finally {

                this.isLoading = false;

            }
        });
    }

    isItmCell(selectedCell: CellClickedEvent): boolean {

        if (!isNullOrUndefined(selectedCell)) {
            return true;
        }

        if (!selectedCell.colDef.field) {
            return true;
        }

        if (!selectedCell.colDef.field.startsWith('pricing')) {
            return true;
        }

        if (!selectedCell.data) {
            return true;
        }

        const objAttributeName = selectedCell.colDef.field.split('.')[0];

        const data = selectedCell.data as PricingGridRow;

        const pricingCol = data[objAttributeName] as PricingGridStrategyColumn;

        if (!pricingCol) {
            return true;
        }

        if (pricingCol.optionType === OptionType.Call) {
            if (isNullOrUndefined(selectedCell.data.callOtm)) {
                return true;
            }
        }

        if (pricingCol.optionType === OptionType.Put) {
            if (isNullOrUndefined(selectedCell.data.putOtm)) {
                return true;
            }
        }
    }


    @DetectMethodChanges()
    showCustomColumnDialog() {
        this.customColumnDialogConfig.visible = true;
    }


    @DetectMethodChanges()
    onAddCustomColumnDialogClosed() {
        this.customColumnDialogConfig = {};
    }

    @DetectMethodChanges()
    private onStrategyApplyClicked(targetSide: 'calls' | 'puts', str: PricingGridStrategy) {

        if (!this._grid) {
            str.markForChanges();
            return;
        }

        if (!isValidNumber(this.defaultQty, true)) {
            this._toastr.error('Please Provide Default Qty');
            return;
        }

        if (this.mode === 'pmd') {
            try {
                this.validateHedges(targetSide);
            } catch {
                str.markForChanges();
                return;
            }
        }


        try {
            const offsetsGood = str.validateOffsets({side: targetSide, expiration: this.selectedExpiration});

            if (!offsetsGood) {
                this._toastr.error('Negative offset is referencing non-existing expiration');
                return;
            }

            this.selectedCell = null;

            const exists = this.pricingRows
                .some(x => x.getPricingColumnsAttributes().some(y => y.indexOf(str.strategyId) > 0));

            if (exists) {

                this.updateExistingStrategyColumns(str);

            } else {

                this.addStrategyColumn(targetSide, str);

            }
        }finally {
            str.afterLegsApplied();

        }

    }

    @DetectMethodChanges({delay: 25})
    private addStrategyColumn(targeSide: 'calls' | 'puts', str: PricingGridStrategy) {

        this.isLoading = true;

        const newDefs = getGridColumnDefinitions(this);

        this._grid.api.setColumnDefs([]);

        setTimeout(() => {

            const codesToSubscribe: string[] = [];

            try {

                if (this.pricingRows.length > 0) {

                    this.pricingRows.forEach(pricingRow => {

                        const codeToSubscribe = pricingRow.addStrategyColumn(targeSide, str);

                        if (!isVoid(codeToSubscribe)) {
                            if (codesToSubscribe.indexOf(codeToSubscribe) === -1) {
                                codesToSubscribe.push(codeToSubscribe);
                            }
                        }
                    });
                }


                this._grid.api.setColumnDefs(newDefs);

                this.changeOptionStrategiesSubscription([], codesToSubscribe);

                this.sizeColumns();

            } finally {
                this.isLoading = false;
                this.scrollToTargetSide(targeSide);
            }

        }, 0);

    }


    @DetectMethodChanges({delay: 25})
    private updateExistingStrategyColumns(str: PricingGridStrategy) {

        this.isLoading = true;

        setTimeout(async () => {

            try {

                const alreadySubscribed = this.getSubscribedStrategyCodes('unsub');

                this.pricingRows.forEach(pricingRow => {
                    pricingRow.updateStrategyColumns(str);
                });

                const toSubscribe = this.getSubscribedStrategyCodes('sub');

                this.changeOptionStrategiesSubscription(alreadySubscribed, toSubscribe);

                this._grid.api.refreshHeader();

                await this.makeHighlights();

            } finally {

                this.isLoading = false;

            }
        }, 0);
    }


    @DetectMethodChanges({isAsync: true})
    private async fillPricingRows(): Promise<void> {

        if (!this.numOfStrikes) {
            return;
        }

        this.selectedCell = null;

        this.isLoading = true;

        this._grid.api.setRowData([]);

        const offsetsGood = this.validateOffsetsForStrategies({ side: 'Calls & Puts', expiration: this.selectedExpiration });

        if (!offsetsGood) {
            this._toastr.error('Negative offset is referencing non-existing expiration');
            return;
        }

        await wrapInPromise(() => {

            try {


                this._rowsByStrategyCode = {};

                const alreadySubscribedTickers = this.getSubscribedTickers();
                const alreadySubscribedCodes = this.getSubscribedStrategyCodes('unsub');


                const strikes = this.getStrikesToShow();
                const rows = strikes.map(strike => new PricingGridRow(strike, this));

                rows.forEach((row: PricingGridRow) => {
                    row.setPricingColumns();

                });

                this.pricingRows = rows;

                const toSubscribeTickers = this.getSubscribedTickers();
                const toSubscribeCodes = this.getSubscribedStrategyCodes('sub');

                this._lastQuoteCache.subscribeTickersDiff(alreadySubscribedTickers, toSubscribeTickers);
                this.changeOptionStrategiesSubscription(alreadySubscribedCodes, toSubscribeCodes);

                this._grid.api.setRowData(rows);

                this.makeHighlights().then(() => {
                });


            } finally {

                this.isLoading = false;

            }

        }, 0);
    }


    private readyToFillPricingRows(): boolean {
        return !!this.numOfStrikes &&
            !!this.selectedExpiration &&
            !!this.tradingInstrument;
    }


    @DetectMethodChanges({isAsync: true})
    private async setNewSymbol(symbol: TradingInstrument): Promise<void> {

        this._grid.api.setRowData([]);

        const lq = this._lastQuoteCache.subscribeTicker(symbol.ticker);

        if (lq) {
            this.lastPx = lq.lastPx;
        }

        this.unsubscribeCurrentTickers();

        const codesToUnsubscribe = this.getSubscribedStrategyCodes('unsub');
        this.changeOptionStrategiesSubscription(codesToUnsubscribe, []);

        this._rowsByStrategyCode = {};

        this.pricingRows = [];

        this.tradingInstrument = symbol;

        const ticker = symbol.ticker;

        try {

            const chain = await this._optionsChainService.getChain(ticker);

            this.availableExpirations = chain.expirations.slice();

            console.debug(`Total expirations available: ${chain.expirations.length}`);

        } finally {
            this.isLoading = false;
        }
    }


    private getStrikesToShow(): number[] {

        let centerStrike = this.centerStrike;

        let centerIx;

        if (!isVoid(centerStrike)) {
            centerIx = this.expiration.strikes.indexOf(centerStrike);
        }

        if (isVoid(centerIx) || centerIx < 0) {

            const lastQuote = this.tradingInstrument
                ? this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker)
                : null;

            centerIx = findAtmStrikeIndex(this.expiration.strikes, lastQuote);
        }

        if (isVoid(centerIx) || centerIx === -1) {
            this._toastr.warning('Center Strike Was Determined with Errors');
            centerIx = Math.ceil(this.expiration.strikes.length / 2 - 1);
        }

        let strikes: number[] = [];

        if (this.strikeStep) {

            const centerIxStrike = this.expiration.strikes[centerIx];

            strikes.push(centerIxStrike);

            let cntr = 1;
            do {
                const nextStrike = centerIxStrike - cntr * this.strikeStep;
                const nextStrikeIX = this.expiration.strikes.indexOf(nextStrike);
                if (nextStrikeIX < 0) {
                    if (nextStrike < this.expiration.strikes[0]) {
                        break;
                    }
                    cntr++;
                    continue;
                }
                strikes.unshift(nextStrike);
                cntr++;
            } while (strikes.length < this.numOfStrikes + 1 && cntr < 1000);


            cntr = 1;
            do {
                const nextStrike = centerIxStrike + cntr * this.strikeStep;
                const nextStrikeIX = this.expiration.strikes.indexOf(nextStrike);
                if (nextStrikeIX < 0) {
                    if (nextStrike > this.expiration.strikes[this.expiration.strikes.length - 1]) {
                        break;
                    }
                    cntr++;
                    continue;
                }
                strikes.push(nextStrike);
                cntr++;
            } while (strikes.length < this.numOfStrikes * 2 + 1 && cntr < 1000);

        } else {

            const topEdge = centerIx - this.numOfStrikes;
            const bottomEdge = centerIx + this.numOfStrikes;
            strikes = this.expiration.strikes.slice(topEdge, bottomEdge + 1);

        }

        if (!isValidNumber(this.centerStrike, true)) {
            this.centerStrike = this.expiration.strikes[centerIx];
        }

        return strikes;
    }


    private copyEtsOptionContractToClipboard(args: GetContextMenuItemsParams, destination: 'hg' | 'ets' = 'ets') {
        const fieldCode = args.column.getColDef().field;

        const gridRow = args.node.data as PricingGridRow;

        const optionType = fieldCode.indexOf('call') >= 0 ? OptionType.Call : OptionType.Put;

        const ticker = optionType === OptionType.Call ? gridRow.callTicker : gridRow.putTicker;

        const expStlye = isCashSettledOptionTicker(ticker) ? 'European' : 'American';

        const displayName = makeOptionDisplayName(gridRow.gridSettings.expiration, optionType, gridRow.rowStrike, expStlye);

        const item: ComboHighlightedItem = {
            accountId: null,
            comboId: null,
            portfolioId: null,
            ticker,
            underlying: gridRow.gridSettings.expiration.underlyingTicker,
            tickerDisplayName: displayName,
            netPosition: this.defaultQty,
            side: MarketSide.Buy,
            itemType: optionType === OptionType.Call ? PortfolioItemType.Call : PortfolioItemType.Put,
            strategyId: null,
        };

        const msg: ComboHighlightedUIMessage = {
            items: [item],
            orderParams: {
                orderLimitPx: (optionType === OptionType.Call ? gridRow.callPrice : gridRow.putPrice) * -1,
                orderDuration: TimeInForce.GTC,
                orderQty: 1,
                orderType: OrderType.Limit
            },
            strategyName: optionType === OptionType.Call ? 'Long Call' : 'Long Put',
        };

        const clipboardKey = destination === 'hg' ? 'hg.opg-data' : 'combo';
        this._clipboardService.put(clipboardKey, msg);
        this._toastr.success('Option copied to clipboard');
    }


    private copyEtsComboToClipboard(args: GetContextMenuItemsParams, destination: 'hg' | 'ets' = 'ets') {

        const cb = this.makeComboItems(args);

        const msg: ComboHighlightedUIMessage = {
            items: cb.items,
            orderParams: {
                orderLimitPx: cb.orderPrice,
                orderDuration: TimeInForce.GTC,
                orderQty: 1,
                orderType: OrderType.Limit
            },
        };

        if (this.mode === 'pmd') {
            const comboHighlightedItem = cb.items[0];

            const isCall = comboHighlightedItem.ticker.indexOf(' Call ') > 0;

            const groupId = isCall ? this.selectedCallHedge?.id : this.selectedPutHedge?.id;

            if (isVoid(groupId)) {
                if (destination === 'hg') {
                    this._toastr.error('Copy Failed: cannot determine hedge\'s group ID');
                    return;
                }
            }

            msg.hedgingGridTransaction = {
                groupId
            };
        }

        const valueGetter = args.column.getDefinition().headerValueGetter as Function;

        if (valueGetter) {
            try {
                const name = valueGetter();
                msg.strategyName = name;
            } catch {
                //
            }
        }

        const clipboardKey = destination === 'hg' ? 'hg.opg-data' : 'combo';
        this._clipboardService.put(clipboardKey, msg);
        this._toastr.success('Combo copied to clipboard');
    }

    private makeComboItems(args: GetContextMenuItemsParams): {
        items: ComboHighlightedItem[],
        orderPrice: number,
        ledgerPrice: () => number
    } {

        if (!this.selectedCell) {
            this._toastr.error('Select Strategy Cell');
            return undefined;
        }

        const fieldCode = this.selectedCell.column.getColDef().field;

        const columnName = fieldCode.split('.')[0];

        const gridRow = this.selectedCell.node.data as PricingGridRow;

        const targetCell = gridRow[columnName] as PricingGridStrategyColumn;

        if (!targetCell) {
            console.error('Cannot find target cell');
            return;
        }

        // const optionType = columnName.indexOf('-call-') >= 0 ? OptionType.Call : OptionType.Put;
        if (isVoid(columnName)) {
            throw new Error('Bad Column Name');
        }

        const optionType = columnName.split('-').indexOf('call') >= 0 ? OptionType.Call : OptionType.Put;

        const legs = (this.mode === 'opg' ? targetCell.legs : targetCell.transLegs)
            .sort((a, b) => b.strike - a.strike);

        const comboItems = legs.map(x => {

            const expStyle = isCashSettledOptionTicker(x.ticker) ? 'European' : 'American';

            const optionTicker = parseOptionTicker(x.ticker);

            let expiration = this.optionChain.expirations
                .find(exp => exp.optionExpirationDate === optionTicker.expiration);

            const displayName = makeOptionDisplayName(
                expiration,
                optionType,
                x.strike,
                expStyle
            );

            const item: ComboHighlightedItem = {
                accountId: null,
                comboId: null,
                portfolioId: null,
                ticker: x.ticker,
                underlying: gridRow.gridSettings.expiration.underlyingTicker,
                tickerDisplayName: displayName,
                netPosition: x.qty,
                side: x.side,
                itemType: optionType === OptionType.Call ? PortfolioItemType.Call : PortfolioItemType.Put,
                strategyId: null
            };

            return item;
        });

        const px = targetCell.price / targetCell.hcfMultiplier;

        return {
            items: comboItems,
            orderPrice: px,
            ledgerPrice: () => targetCell.price
        };
    }


    private getColumnDefinitions(): ColDef[] {
        if (!this._grid) {
            return [];
        }

        const colDefs = this._grid.api.getColumnDefs();

        const callsGroup: ColDef[] = (colDefs.find((cg: ColGroupDef) => cg.groupId === 'calls') || {} as any).children || [];
        const putsGroup: ColDef[] = (colDefs.find((cg: ColGroupDef) => cg.groupId === 'puts') || {} as any).children || [];

        const total = callsGroup.concat(putsGroup);
        return total;
    }


    @DetectMethodChanges()
    private async makeHighlights() {
        this.pricingRows.forEach(row => {
            row.resetCellHighlights();
            row.makeHighlights();
        });
        this._grid.api.refreshCells({force: true});
    }

    getLegColor(leg: PricingGridLegDescriptor): string {

        if (leg.side === 'Sell') {
            return 'color: red';
        }

        if (leg.side === 'Buy') {
            return 'color: cornflowerblue';
        }

        return undefined;

    }

    // covered by grid's cell style
    onRMultipleChanged(ev): void {

        this.isLoading = true;

        setTimeout(() => {
            try {

                this.pricingRows.forEach(row => row.highlightRMultiple());

            } finally {

                this._grid.api.refreshCells({force: true});
                this.isLoading = false;

            }
        }, 0);

    }

    // covered by grid's cell style
    onPercentProfitChanged(ev): void {

        this.isLoading = true;

        setTimeout(() => {
            try {

                this.pricingRows.forEach(row => row.highlightPercentageProfit());

            } finally {

                this._grid.api.refreshCells({force: true});
                this.isLoading = false;

            }
        }, 0);

    }

    onTargetRangeChanged(): void {

        const cols2refresh = ['callPrice', 'putPrice'];

        this.isLoading = true;

        this.saveOpgState('opg.is-custom-range', this.isCustomRange);

        if (this.isCustomRange) {
            this.saveOpgState('opg.call-range-start', this.callsTargetRangeStart);
            this.saveOpgState('opg.call-range-end', this.callsTargetRangeEnd);
            this.saveOpgState('opg.put-range-start', this.putsTargetRangeStart);
            this.saveOpgState('opg.put-range-end', this.putsTargetRangeEnd);
        } else {
            this.saveOpgState('opg.call-offset', this.callOffset);
            this.saveOpgState('opg.call-range', this.callRange);
            this.saveOpgState('opg.put-offset', this.putOffset);
            this.saveOpgState('opg.put-range', this.putRange);
        }


        setTimeout(() => {
            try {

                this.pricingRows.forEach(row => row.highlightTargetRange());

            } finally {

                this._grid.api.refreshCells({columns: cols2refresh, force: true});
                this.isLoading = false;

            }

        }, 0);
    }

    private onQuote(quotes: QuoteDto[]) {
        if (!this.tradingInstrument) {
            return;
        }

        setTimeout(() => {
            const quote = quotes.find(q => q.ticker === this.tradingInstrument.ticker);
            if (quote) {
                this.lastPx = quote.lastPx;
                this._changeDetector.detectChanges();
            }
        });

        const updatedRows = [];

        this.pricingRows.forEach(row => {
            const isAffected = row.onQuote(quotes);
            if (isAffected) {
                updatedRows.push(row);
            }
        });

        this.pricingRows.forEach(row => row.highlightPayForIt());

        this._grid.api.applyTransactionAsync({update: updatedRows});
    }

    private onGreeks(x: GreeksDto): void {

        if (!this.tradingInstrument) {
            return;
        }

        const node: RowNode = this._grid.api.getRowNode(x.strike + '');

        if (!node) {
            return;
        }

        const line = node.data as PricingGridRow;

        if (!line) {
            return;
        }

        line.onGreeks(x);

        this._grid.api.applyTransactionAsync({update: [line]});
    }

    private getSubscribedTickers(): string[] {
        const atmStrike = this.atmStrike;
        const showItm = this.showItmData;

        const tickers = this.pricingRows.flatMap(x => x.getTickers());
        const uq = Array.from(new Set(tickers));
        return uq;
    }

    private getSubscribedStrategyCodes(purpose: 'sub' | 'unsub'): string[] {

        const strategyCodes = this.pricingRows
            .flatMap(x =>
                x.getStrategyCodes(purpose)
            ).filter(x => !isNullOrUndefined(x));

        if (purpose === 'sub') {
            console.log('sub', strategyCodes);
        } else {
            console.log('unsub', strategyCodes);
        }

        return strategyCodes;
    }

    private getItmStrategyCodes(): string[] {
        const strategyCodes = this.pricingRows
            .flatMap(x =>
                x.getItmStrategyCodes()
            ).filter(x => !isNullOrUndefined(x));

        return strategyCodes;
    }

    private changeOptionStrategiesSubscription(unsubscribe: string[], subscribe: string[]) {

        this.updateRowToStrategyIndex();

        this._lastQuoteCache.subscribeStrategyCodesDiff(
            unsubscribe.filter(x => !isNullOrUndefined(x)),
            subscribe.filter(x => !isNullOrUndefined(x)));
    }

    private unsubscribeCurrentTickers() {
        const tickers = this.getSubscribedTickers();
        this._lastQuoteCache.unsubscribeTickers(tickers.filter(x => !isNullOrUndefined(x)));
    }

    private updateRowToStrategyIndex() {
        this.pricingRows.forEach(row => {
            const codes = row.getStrategyCodes('unsub').filter(x => !isVoid(x));
            codes.forEach(code => this._rowsByStrategyCode[code] = row);
        });
    }

    private onStrategyPriceDto(messages: StrategyPriceDto[]): void {

        if (!this._grid) {
            return;
        }

        const rows = [];

        messages.forEach(x => {

            const row = this._rowsByStrategyCode[x.strategyCode];

            if (!row) {
                return;
            }

            const col = row.onStrategyPrice(x);

            if (!isVoid(col)) {
                rows.push(row);
            }

        });

        this._grid.api.applyTransactionAsync({update: rows});

    }

    protected getState(): PanelState {

        const state: PanelState = {
            isLinkedToSymbol: this.isLinkedToSymbol
        };

        return state;
    }


    protected setState(state: PanelState) {

        if (state) {
            this.isLinkedToSymbol = state.isLinkedToSymbol;
        }

    }

    @DetectMethodChanges({isAsync: true})
    async newTemplate(targetSide: 'calls' | 'puts') {

        if (this.isWrongSide(targetSide)) {
            return Promise.resolve();
        }

        let title;

        try {
            title = await this.editTemplatePopupModel.show('Save As Template');
        } catch {
            return;
        }

        const container = targetSide === 'calls' ? this.callStrategies : this.putStrategies;

        const pricingGridStrategyDescriptors = container.map(x => {
            const d = x.getDescriptor();
            d.strategyId = getShortUUID();
            return d;
        });

        const tpl = {
            templateName: title,
            templateId: getShortUUID(),
            underlying: this.tradingInstrument.ticker,
            descriptors: pricingGridStrategyDescriptors
        }

        this._templatesService.save(tpl, targetSide);

        await this.onTemplateSelected(targetSide, { value: tpl, event: 'ets' })

        // if (targetSide === 'calls') {
        //     this.callsSelectedTemplate = tpl;
        // } else {
        //     this.putsSelectedTemplate = tpl;
        // }

        this._toastr.success(`Template saved!`);
    }


    @DetectMethodChanges({isAsync: true})
    async saveTemplate(targetSide: 'calls' | 'puts') {

        if (this.isWrongSide(targetSide)) {
            return Promise.resolve();
        }

        let tpl = targetSide === 'calls' ? this.callsSelectedTemplate : this.putsSelectedTemplate;

        if (isVoid(tpl)) {
            this._toastr.error('Template Not Selected');
            return Promise.resolve();
        }

        const container = targetSide === 'calls' ? this.callStrategies : this.putStrategies;

        const pricingGridStrategyDescriptors = container.map(x => x.getDescriptor());

        tpl.descriptors = pricingGridStrategyDescriptors;

        this._templatesService.save(tpl, targetSide);

        this._toastr.success(`Template saved!`);
    }


    @DetectMethodChanges()
    toggleSidePanel(side: 'calls' | 'puts') {
        if (side === 'puts') {
            this.putsSidePanelVisible = !this.putsSidePanelVisible;
        } else if (side === 'calls') {
            this.callsSidePanelVisible = !this.callsSidePanelVisible;
        } else {
            return;
        }
    }

    getGridStrategies(targetSide: 'calls' | 'puts') {
        if (targetSide === 'calls') {
            return this.callStrategies;
        }

        if (targetSide === 'puts') {
            return this.putStrategies;
        }

        return [];
    }

    isSidePanelVisible(targetSide: 'calls' | 'puts') {
        if (targetSide === 'calls') {
            return this.callsSidePanelVisible;
        }

        if (targetSide === 'puts') {
            return this.putsSidePanelVisible;
        }

        return false;
    }

    canApplyAllStrategies(targetSide: 'calls' | 'puts'): boolean {
        if (this.isWrongSide(targetSide)) {
            return false;
        }

        if (targetSide === 'calls') {
            return this.callStrategies.length > 0 && this.callStrategies.some(x => x.canApplyLegs)
        } else {
            return this.putStrategies.length > 0 && this.putStrategies.some(x => x.canApplyLegs);
        }
    }

    applyAllStrategies(targetSide: 'calls' | 'puts') {

        if (this.isWrongSide(targetSide)) {
            return;
        }

        if (!isValidNumber(this.defaultQty, true)) {
            this._toastr.error('Please Provide Default Qty');
            return;
        }

        if (this.mode === 'pmd') {
            try {
                this.validateHedges(targetSide);
            } catch {
                return;
            }
        }

        const offsetsGood = this.validateOffsetsForStrategies({ side: targetSide, expiration: this.selectedExpiration });

        if (!offsetsGood) {
            this._toastr.error('Negative offset is referencing non-existing expiration');
            return;
        }

        this.selectedCell = null;

        let ids = [];

        if (targetSide === 'calls') {
            this.callStrategies.forEach(x => {
                if (x.canApplyLegs) {
                    x.applyLegs();
                }
            });
            ids = this.callStrategies.map(x => x.strategyId);
        } else {
            this.putStrategies.forEach(x => {
                if (x.canApplyLegs) {
                    x.applyLegs();
                }
            });
            ids = this.putStrategies.map(x => x.strategyId);
        }

        setTimeout(() => {

            const cols = this._grid.columnApi.getAllColumns().filter(x => {
                const matches = ids.some(id => x.getColId().indexOf(id) >= 0);
                return matches;
            });

            this._grid?.columnApi.autoSizeColumns(cols);

        }, 500);
    }

    togglePropagateRange(calls: 'calls' | 'puts') {
        if (this.isWrongSide(calls)) {
            return;
        }

        this.onTargetRangeChanged();
    }

    @DetectMethodChanges({isAsync: true})
    async removeTemplate(targetSide: 'calls' | 'puts') {
        if (this.isWrongSide(targetSide)) {
            return;
        }

        const tpl = targetSide === 'calls' ? this.callsSelectedTemplate : this.putsSelectedTemplate;

        const text: string[] = [
            'Are you sure you want to delete',
            ` [${tpl.templateName}]`,
            ' template?'
        ]

        try {
            await this.confirmationDialogCmp.show(text);
        } catch {
            return;
        }

        this._templatesService.remove(tpl, targetSide);

        if (this.callsSelectedTemplate === tpl) {
            this.callsSelectedTemplate = null;
        }

        if (this.putsSelectedTemplate === tpl) {
            this.putsSelectedTemplate = null;
        }

        this._toastr.success('Template Removed!');

    }

    @DetectMethodChanges({isAsync: true})
    async editTemplate(targetSide: 'calls' | 'puts'): Promise<void> {

        if (this.isWrongSide(targetSide)) {
            return Promise.resolve();
        }

        const tpl = targetSide === 'calls' ? this.callsSelectedTemplate : this.putsSelectedTemplate;

        if (isVoid(tpl)) {
            return Promise.resolve();
        }

        let title;

        try {
            title = await this.editTemplatePopupModel.show('Edit Template', tpl.templateName);
        } catch {
            return;
        }

        tpl.templateName = title;

        this._templatesService.save(tpl, targetSide);

        if (targetSide === 'calls') {
            this.callsSelectedTemplate = null;
        } else {
            this.putsSelectedTemplate = null;
        }

        setTimeout(() => {
            if (targetSide === 'calls') {
                this.callsSelectedTemplate = tpl;
            } else {
                this.putsSelectedTemplate = tpl;
            }
            this._changeDetector.detectChanges();
            this._toastr.success(`Template saved!`);
        });
    }


    canRemoveTemplate(targetSide: 'calls' | 'puts') {
        if (this.isWrongSide(targetSide)) {
            return false;
        }

        if (targetSide === 'calls') {
            return !isVoid(this.callsSelectedTemplate);
        } else {
            return !isVoid(this.putsSelectedTemplate);
        }
    }

    canRenameTemplate(targetSide: 'calls' | 'puts') {
        if (this.isWrongSide(targetSide)) {
            return false;
        }

        if (targetSide === 'calls') {
            return !isVoid(this.callsSelectedTemplate);
        } else {
            return !isVoid(this.putsSelectedTemplate);
        }
    }

    canSaveTemplate(targetSide: 'calls' | 'puts') {
        if (this.isWrongSide(targetSide)) {
            return false;
        }

        if (targetSide === 'calls') {
            return !isVoid(this.callsSelectedTemplate);
        } else {
            return !isVoid(this.putsSelectedTemplate);
        }
    }

    canNewTemplate(targetSide: 'calls' | 'puts') {
        if (this.isWrongSide(targetSide)) {
            return false;
        }

        if (targetSide === 'calls') {
            return !isVoid(this.callStrategies)
                && this.callStrategies.every(x => !isVoid(x.strategyLegs));
        } else {
            return !isVoid(this.putStrategies)
                && this.putStrategies.every(x => !isVoid(x.strategyLegs));
        }
    }

    private sizeColumns() {
        if (!this._grid) {
            return;
        }

        const strategiesCount = this.callStrategies.length + this.putStrategies.length;

        if (strategiesCount >= 4) {
            this._grid.columnApi.autoSizeAllColumns();
        } else {
            this._grid.api.sizeColumnsToFit();
        }
    }

    private saveOpgState(key: OpgStateKey, value: any) {
        if (isVoid(this.tradingInstrument)) {
            return;
        }
        let storageKey = key as any;
        if (key !== 'opg.symbol') {
            storageKey = `${this.tradingInstrument.ticker}.${storageKey}`;
        }
        this._userSettingsService.setValue(storageKey, value);
    }

    private async applyOpgState(ti?: TradingInstrument) {
        let key: OpgStateKey = 'opg.symbol';

        if (isVoid(ti)) {
            const symbol = this._userSettingsService.getValue<string>(key);
            if (!isVoid(symbol)) {
                await this.onSymbolHighlightedMessage({ticker: symbol, source: 'Watchlist'});
            }

            return;
        }


        const ticker = ti.ticker;

        const lastQuote = await this._lastQuoteCache.getLastQuoteWithAwait(ticker);

        key = `opg.expiration`;
        let storageKey = `${ticker}.${key}`;
        const expiration = this._userSettingsService.getValue(storageKey);
        if (!isVoid(expiration)) {
            let exp = this.availableExpirations.find(x => x.optionExpirationDate === expiration);
            if (isVoid(exp)) {
                exp = this.availableExpirations[0];
            }
            this.selectedExpiration = exp;
        } else {
            this.selectedExpiration = this.availableExpirations[0];
        }

        key = 'opg.step';
        storageKey = `${ticker}.${key}`;
        const step = this._userSettingsService.getValue<number>(storageKey);
        const iStep = step; //parseInt(step);
        if (isValidNumber(iStep, true)) {
            this.strikeStep = iStep;
        } else {
            this.strikeStep = ti.ticker === 'SPX' ? 5 : 1;
        }

        key = 'opg.center-strike';
        storageKey = `${ticker}.${key}`;
        const centerStrike = this._userSettingsService.getValue<number>(storageKey);
        const iCenterStrike = centerStrike;// parseInt(centerStrike);
        if (isValidNumber(iCenterStrike, true)) {
            this.centerStrike = iCenterStrike;
        } else {
            this.centerStrike = undefined;
        }

        key = 'opg.num-of-strikes';
        storageKey = `${ticker}.${key}`;
        const numOfStrikes = this._userSettingsService.getValue<number>(storageKey);
        const iNumOfStrikes = numOfStrikes;// parseInt(numOfStrikes);
        if (isValidNumber(iNumOfStrikes, true)) {
            this.numOfStrikes = iNumOfStrikes;
        } else {
            this.numOfStrikes = 25;
        }


        let atmStrike = this.centerStrike;
        if (isVoid(atmStrike)) {
            const atmIx = findAtmStrikeIndex(this.selectedExpiration.strikes, lastQuote);
            atmStrike = this.selectedExpiration.strikes[atmIx];
        }

        key = 'opg.call-range-start';
        storageKey = `${ticker}.${key}`;
        const callRangeStart = this._userSettingsService.getValue<number>(storageKey);
        const iCallRangeStart = callRangeStart;// parseInt(callRangeStart);
        if (isValidNumber(iCallRangeStart, true)) {
            this.callsTargetRangeStart = iCallRangeStart;
        } else {
            this.callsTargetRangeStart = atmStrike + (ticker === 'SPX' ? 50 : 5);
        }

        key = 'opg.call-range-end';
        storageKey = `${ticker}.${key}`;
        const callRangeEnd = this._userSettingsService.getValue<number>(storageKey);
        const iCallRangeEnd = callRangeEnd; //parseInt(callRangeEnd);
        if (isValidNumber(iCallRangeEnd, true)) {
            this.callsTargetRangeEnd = iCallRangeEnd;
        } else {
            this.callsTargetRangeEnd = this.callsTargetRangeStart + (ticker === 'SPX' ? 50 : 5);
        }

        key = 'opg.put-range-start';
        storageKey = `${ticker}.${key}`;
        const putRangeStart = this._userSettingsService.getValue<number>(storageKey);
        const iPutRangeStart = putRangeStart;// parseInt(putRangeStart);
        if (isValidNumber(iPutRangeStart, true)) {
            this.putsTargetRangeStart = iPutRangeStart;
        } else {
            this.putsTargetRangeStart = atmStrike - (ticker === 'SPX' ? 50 : 5);
        }

        key = 'opg.put-range-end';
        storageKey = `${ticker}.${key}`;
        const putRangeEnd = this._userSettingsService.getValue<number>(storageKey);
        const iPutRangeEnd = isValidNumber(putRangeEnd) ? putRangeEnd : null; // parseInt(putRangeEnd);
        if (isValidNumber(iPutRangeEnd, true)) {
            this.putsTargetRangeEnd = iPutRangeEnd;
        } else {
            this.putsTargetRangeEnd = this.putsTargetRangeStart - (ticker === 'SPX' ? 50 : 5);
        }

        key = 'opg.is-custom-range';
        storageKey = `${ticker}.${key}`;
        const isCustomRange = this._userSettingsService.getValue<boolean>(storageKey);
        const bIsCustomRange = isCustomRange || false; // isCustomRange === 'true';
        this.isCustomRange = bIsCustomRange;

        key = 'opg.call-offset';
        storageKey = `${ticker}.${key}`;
        const callOffset = this._userSettingsService.getValue<number>(storageKey);
        const iCallOffset = isValidNumber(callOffset) ? callOffset : null; // parseInt(callOffset) || null;
        this.callOffset = iCallOffset;

        key = 'opg.put-offset';
        storageKey = `${ticker}.${key}`;
        const putOffset = this._userSettingsService.getValue<number>(storageKey);
        const iPutOffset = isValidNumber(putOffset) ? putOffset : null; // parseInt(putOffset) || null;
        this.putOffset = iPutOffset;

        key = 'opg.call-range';
        storageKey = `${ticker}.${key}`;
        const callRange = this._userSettingsService.getValue<number>(storageKey);
        const iCallRange = isValidNumber(callRange) ? callRange : null; // parseInt(callRange) || null;
        this.callRange = iCallRange;

        key = 'opg.put-range';
        storageKey = `${ticker}.${key}`;
        const putRange = this._userSettingsService.getValue<number>(storageKey);
        const iPutRange = isValidNumber(putRange) ? putRange : null; // parseInt(putRange) || null;
        this.putRange = iPutRange;

        key = 'opg.default-qty.own';
        storageKey = `${ticker}.${key}`;
        const defaultQtyCustom = this._userSettingsService.getValue<number>(storageKey);
        const iDefaultQtyCustom = isValidNumber(defaultQtyCustom) ? defaultQtyCustom : null;// parseInt(defaultQtyCustom) || null;
        this.defaultQty = iDefaultQtyCustom;
    }


    private async copyTOSComboToClipboard(args: GetContextMenuItemsParams) {

        const cbItems = this.makeComboItems(args);

        if (isVoid(cbItems) || isVoid(cbItems.items)) {
            this._toastr.warning('No Legs to Copy!');
            return;
        }

        let hcf = 1;
        let orderQty = 1;

        const transformedLegs = cbItems.items
            .map(x => {

                const optionTicker = parseOptionTicker(x.ticker);

                const qty = (x.netPosition / hcf) * x.side;

                const date = DateTime.fromFormat(optionTicker.expiration, 'yyyy-MM-dd')
                    .toFormat('dd MMM yy')
                    .toUpperCase();

                const strike = optionTicker.strike;
                const type = optionTicker.type.toUpperCase();
                const price = cbItems.orderPrice;

                return {
                    qty,
                    date,
                    strike,
                    type,
                    price
                };
            });

        const underlying = cbItems.items[0].underlying;

        const quantities = transformedLegs.map(x => x.qty).join('/');
        const dates = transformedLegs.map(x => x.date).join('/');
        const strikes = transformedLegs.map(x => x.strike).join('/');
        const types = transformedLegs.map(x => x.type).join('/');
        const comboPrice = cbItems.orderPrice * -1;

        if (!isValidNumber(comboPrice)) {
            return undefined;
        }


        let line: string;
        if (underlying === 'SPX') {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 (Weeklys) ${dates} ${strikes} ${types} @ LMT GTC`;
        } else {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 ${dates} ${strikes} ${types} @ LMT GTC`;
        }

        await navigator.clipboard.writeText(line);

        this._toastr.success('Orders Copied to Clipboard!');
    }

    private async copyTOSOptionContractToClipboard(args: GetContextMenuItemsParams) {

        const fieldCode = args.column.getColDef().field;

        const gridRow = args.node.data as PricingGridRow;

        const optionType = fieldCode.indexOf('call') >= 0 ? OptionType.Call : OptionType.Put;

        const ticker = optionType === OptionType.Call ? gridRow.callTicker : gridRow.putTicker;

        const expStlye = isCashSettledOptionTicker(ticker) ? 'European' : 'American';

        const displayName = makeOptionDisplayName(gridRow.gridSettings.expiration, optionType, gridRow.rowStrike, expStlye);

        const underlying = gridRow.gridSettings.expiration.underlyingSymbol;

        const orderQty = 1;

        const quantities = this.defaultQty;

        const optionTicker = parseOptionTicker(ticker);

        const dates = DateTime.fromFormat(optionTicker.expiration, 'yyyy-MM-dd')
            .toFormat('dd MMM yy')
            .toUpperCase();

        const strikes = optionTicker.strike;

        const types = optionTicker.type.toUpperCase();

        let line: string;
        if (underlying === 'SPX') {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 (Weeklys) ${dates} ${strikes} ${types} @ LMT GTC`;
        } else {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 ${dates} ${strikes} ${types} @ LMT GTC`;
        }

        await navigator.clipboard.writeText(line);

        this._toastr.success('Order Copied to Clipboard!');
    }

    @DetectMethodChanges()
    onChange() {

    }

    private async initPortfolioService() {

        this.isLoading = true;

        try {

            const users = this._sessionService.sessionData.users
                .sort((a, b) => a.userName.localeCompare(b.userName));

            const grps = [];

            for (const user of users) {
                const grp = {
                    key: user.userName,
                    userId: user.userId,
                    items: []
                };

                const cfg: ServiceConfiguration = {
                    orientation: undefined,
                    userId: user.userId,
                    userName: user.userName
                };

                this._portfolioService.configure(cfg);

                const pfs = this._portfolioService.getPortfolios();

                const defaultPortfolios = pfs.filter(x => x.id.startsWith('----'));

                for (const defaultPortfolio of defaultPortfolios) {
                    const ul = await this._apgDataService.getUnderlyingOfPortfolio(defaultPortfolio);
                    defaultPortfolio.asset = ul;
                }

                grp.items.push(...pfs);

                grps.push(grp);
            }

            const ownUser = this.getUserObject(this._sessionService.sessionData.userId);

            this._portfolioService.configure({
                orientation: undefined,
                userId: ownUser.userId,
                userName: ownUser.userName
            });

            const pfs = this._portfolioService.getPortfolios();

            const defaultPortfolios = pfs.filter(x => x.id.startsWith('----'));

            for (const defaultPortfolio of defaultPortfolios) {
                const ul = await this._apgDataService.getUnderlyingOfPortfolio(defaultPortfolio);
                defaultPortfolio.asset = ul;
            }

            const ownGrp = {
                key: ownUser.userName,
                userId: ownUser.userId,
                items: pfs
            }

            grps.unshift(ownGrp);

            this.portfolioList = grps;

        } finally {

            this.isLoading = false;

        }
    }

    private getUserObject(userId: string): UserDto {

        if (this._sessionService.sessionData.userId === userId) {

            const ownUserName = this._sessionService.sessionData.firstName + ' ' + this._sessionService.sessionData.lastName[0] + '.';

            return {
                userId,
                userName: ownUserName
            };

        } else {

            return this._sessionService.sessionData.users.find(x => x.userId === userId);
        }
    }


    private async hedgePosistions() {
        const hedgePositions =
            await this._hedgePositionService.getHedgePositions(this.selectedCallsPortfolio);
    }

    getSelectedPortfolio(side: 'calls' | 'puts') {
        return side === "calls" ? this.selectedCallsPortfolio
            : this.selectedPutsPortfolio;
    }

    @DetectMethodChanges()
    onPortfolioSelecting(portfolio: ApgPortfolio, targetSide: 'calls' | 'puts') {
        if (targetSide === 'calls') {
            this.selectedCallsPortfolio = portfolio;
        } else {
            this.selectedPutsPortfolio = portfolio;
        }

        this._hedgesPricingService
            .subscribe(portfolio)
            .then(() => {
            });

        this._apgDataService.getPortfolioPositions(portfolio)
            .then(positions => {
                const soPos = positions
                    .flatMap(x => x).find(x => x.role === 'ShortOption');

                if (!soPos) {
                    this._toastr.warning('Unable to determine default quantity for selected portfolio. Your short options probably expired');
                    return;
                }

                const isExpired = isOptionExpired(soPos.ticker);

                if (isExpired) {
                    this._toastr.warning('Some positions in this portfolio are expired');
                    return;
                }


                const qty = Math.abs(soPos.qty);

                if (!isValidNumber(qty, true)) {
                    this._toastr.warning('Unable to determine default quantity for selected portfolio. Your short options probably expired');
                    return;
                }

                this.defaultQty = qty;
            });

        setTimeout(() => {
            this.isLoading = true;
            this.updateHedges(portfolio, targetSide)
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    private async updateHedges(portfolio: ApgPortfolio, targetSide: "calls" | "puts") {

        const hedgePositions = await this._hedgePositionService.getHedgePositions(portfolio);

        const type = targetSide === 'calls' ? 'Call' : 'Put';

        const hedgePositions1 = hedgePositions
            .filter(x => x.type === type)
            .filter(x => !isOptionExpired(x.ticker));

        const hedgeGroups = Enumerable.from(hedgePositions1)
            .groupBy(x => x.groupId)
            .select(x => {
                return {
                    id: x.key(),
                    label: x.first()?.label,
                    items: x.toArray(),
                }
            })
            .toArray();

        if (targetSide === 'calls') {
            this.callHedgesList = hedgeGroups;
        } else {
            this.putHedgesList = hedgeGroups;
        }
    }

    getHedgesList(target: 'calls' | 'puts') {
        return target === "calls" ? this.callHedgesList
            : this.putHedgesList;
    }

    getSelectedHedge(targetSide: 'calls' | 'puts') {
        return targetSide === "calls" ? this.selectedCallHedge
            : this.selectedPutHedge;
    }

    @DetectMethodChanges()
    async onHedgeSelected(args: DxValueChanged<{ items: HedgePosition[] }>, targetSide: 'calls' | 'puts') {

        try {

            if (isVoid(args.event)) {
                return;
            }

            this.isLoading = true;

            const hedge = args.value;

            if (targetSide === 'calls') {
                if (this.selectedCallHedge === hedge) {
                    return;
                }
                this.selectedCallHedge = hedge as any;
            } else {
                if (this.selectedPutHedge === hedge) {
                    return;
                }
                this.selectedPutHedge = hedge as any;
            }

            if (isVoid(hedge)) {
                return;
            }

            if (hedge.items.length === 0) {
                return;
            }

            // Sync symbol if not selected
            if (isVoid(this.tradingInstrument)) {
                await this.onSymbolHighlightedMessage({ticker: hedge.items[0].asset, source: 'Watchlist'})
            }

            // Let's sync hedge expiration

            const hedgeItem = hedge.items[0];

            const chain = await this._optionsChainService.getChain(hedgeItem.asset);

            if (isVoid(chain)) {
                this._toastr.error('Unable to get option chains for selected underlying asset');
                return;
            }

            const expirationDate = this.selectedExpiration?.optionExpirationDate;

            if (expirationDate !== hedgeItem.expiration) {
                const exp = chain.expirations
                    .find(x => x.optionExpirationDate === hedgeItem.expiration);

                if (isVoid(exp)) {
                    this._toastr.error('Unable to get option chains for selected underlying asset');
                    return;
                }

                this.selectedExpiration = exp;
            }

            if (targetSide === 'calls') {
                this.callStrategies.forEach(x => x.markForChanges());
            } else if (targetSide === 'puts') {
                this.putStrategies.forEach(x => x.markForChanges());
            }

        } finally {

            this.isLoading = false;


            this.pricingRows.forEach(x => {
                x.getPricingColumnsAttributes().forEach(attr => {
                    (x[attr] as PricingGridStrategyColumn).resetPmdState();
                });
                x.resetCellHighlights();
                x.highlightOwnLegs(targetSide);
            });
            this._grid.api.refreshCells({force: true});
        }

    }

    customLegSideList: Array<any> = [
        {name: 'Buy', value: 1, color: 'cornflowerblue'},
        {name: 'Sell', value: -1, color: 'red'}
    ];

    customCallHedgeLegs: CustomHedgeLeg[] = [];

    customPutHedgeLegs: CustomHedgeLeg[] = [];

    getCustomLegs(targetSide: 'calls' | 'puts'): CustomHedgeLeg[] {
        if (targetSide === 'calls') {
            return this.customCallHedgeLegs
                .sort((a, b) => b?.strike - a?.strike);
        }
        if (targetSide === 'puts') {
            return this.customPutHedgeLegs
                .sort((a, b) => b?.strike - a?.strike);
        }
    }

    @DetectMethodChanges()
    addCustomLeg(targetSide: 'calls' | 'puts') {
        const leg = new CustomHedgeLeg(this._changeDetector,
            targetSide,
            this
        );

        leg.propertChanged$.subscribe(x => {
            if (targetSide === 'calls') {
                this.callStrategies.forEach(x => x.markForChanges());
            } else if (targetSide === 'puts') {
                this.putStrategies.forEach(x => x.markForChanges());
            }

            this.pricingRows.forEach(x => x.highlightOwnLegs(targetSide));
            this._grid.api.refreshCells({force: true});
        });

        if (targetSide === 'calls') {
            this.customCallHedgeLegs.push(leg);
            this.callStrategies.forEach(x => x.markForChanges());
        } else if (targetSide === 'puts') {
            this.customPutHedgeLegs.push(leg);
            this.putStrategies.forEach(x => x.markForChanges());
        }

        this.selectedCell = undefined;
        this.pricingRows.forEach(x => {
            x.getPricingColumnsAttributes().forEach(attr => {
                (x[attr] as PricingGridStrategyColumn).resetPmdState();
            });
            x.resetCellHighlights();
            x.highlightOwnLegs();
        });
        this._grid.api.refreshCells({force: true});
    }

    getStyleForCustomLegSide(leg: CustomHedgeLeg) {
        return {
            style: `color: ${leg.side?.color}; text-align: center;`
        }
    }

    getExistingHedges(side: OptionType): HedgePosition[] {
        if (side === OptionType.Call) {
            if (!isVoid(this.selectedCallHedge)) {
                return this.selectedCallHedge.items;
            }
            if (!isVoid(this.customCallHedgeLegs)) {
                const legs = this.customCallHedgeLegs.map(x => x.asHedgePosition())
                    .filter(x => !isVoid(x));
                return legs;
            }
        } else if (side === OptionType.Put) {
            if (!isVoid(this.selectedPutHedge)) {
                return this.selectedPutHedge.items;
            }
            if (!isVoid(this.customPutHedgeLegs)) {
                const legs = this.customPutHedgeLegs.map(x => x.asHedgePosition())
                    .filter(x => !isVoid(x));
                return legs;
            }
        }
        return [];
    }

    pmdHedgeMode: 'custom' | 'existing' = 'existing';

    private validateHedges(side: 'calls' | 'puts') {

        if (side !== 'calls' && side !== 'puts') {
            throw Error();
        }

        const mode = this.pmdHedgeMode;

        if (mode === 'custom') {
            this.validateCustomHedges(side);
        } else {
            this.validateExistingHedges(side);
        }
    }

    private validateExistingHedges(side: "calls" | "puts") {
        if (side !== 'calls' && side !== 'puts') {
            throw Error();
        }

        const selectedHedge = side === 'calls' ? this.selectedCallHedge : this.selectedPutHedge;

        const positions = selectedHedge?.items;

        if (isVoid(positions)) {
            this._toastr.error('Please Select Valid Existing Hedge');
            throw new Error();
        }
    }

    private validateCustomHedges(side: 'calls' | 'puts') {

        if (side !== 'calls' && side !== 'puts') {
            throw Error();
        }

        const legs: CustomHedgeLeg[] = side === 'calls' ? this.customCallHedgeLegs : this.customPutHedgeLegs;

        if (isVoid(legs)) {
            this._toastr.error('Please Add Custom Hedge Legs');
            throw new Error();
        }

        const someBadLegs = legs.some(x =>
            isVoid(x.qty) || isVoid(x.side) || isVoid(x.strike)
        );

        if (someBadLegs) {
            this._toastr.error('Please, Fix Custom Hedge Legs Configuration');
            throw new Error();
        }
        return;
    }

    @DetectMethodChanges()
    removeCustomLeg(leg: CustomHedgeLeg, targetSide: 'calls' | 'puts') {

        const legs: CustomHedgeLeg[] = targetSide === 'calls' ? this.customCallHedgeLegs : this.customPutHedgeLegs;

        if (isVoid(legs)) {
            return;
        }

        try {

            if (isVoid(leg)) {
                legs.length = 0;
                return;
            }

            const ix = legs.indexOf(leg);

            if (ix < 0) {
                return;
            }

            legs.splice(ix, 1);

        } finally {

            if (targetSide === 'calls') {
                this.callStrategies.forEach(x => x.markForChanges());
            } else if (targetSide === 'puts') {
                this.putStrategies.forEach(x => x.markForChanges());
            }

            this.selectedCell = undefined;
            this.pricingRows.forEach(x => {
                x.getPricingColumnsAttributes().forEach(attr => {
                    (x[attr] as PricingGridStrategyColumn).resetPmdState();
                });
                x.resetCellHighlights();
                x.highlightOwnLegs();
            });
            this._grid.api.refreshCells({force: true});
        }
    }

    private updateOwnHighlights(targetSide: 'calls' | 'puts' | undefined = undefined) {
        this.pricingRows.forEach(x => {
            x.highlightOwnLegs(targetSide);
        });
        this._grid.api.refreshCells({force: true});
    }

    getHedgesSectionTitle(side: 'calls' | 'puts'): string {
        if (side === 'calls') {
            return this.pmdHedgeMode === 'custom' ? 'Custom Call Hedge' : 'Your Call Hedges';
        }

        if (side === 'puts') {
            return this.pmdHedgeMode === 'custom' ? 'Custom Put Hedge' : 'Your Put Hedges';
        }

        return 'Hedges Section?';
    }

    @DetectMethodChanges()
    onChangeHedgesModeClicked(side: 'calls' | 'puts') {
        if (this.pmdHedgeMode === 'custom') {

            this.pmdHedgeMode = 'existing';

            this.customCallHedgeLegs = [];
            this.customPutHedgeLegs = [];

        } else {
            this.pmdHedgeMode = 'custom';

            this.selectedCallsPortfolio = undefined;
            this.selectedCallHedge = undefined;
            this.callHedgesList = [];

            this.selectedPutsPortfolio = undefined;
            this.selectedPutHedge = undefined;
            this.putHedgesList = [];
        }

        const key: OpgStateKey = this.pmdHedgeMode === 'existing' ? 'opg.default-qty.own' : 'opg.default-qty.custom';
        const storageKey = `${this.tradingInstrument?.ticker}.${key}`;
        const defaultQtyCustom = this._userSettingsService.getValue<number>(storageKey);
        const iDefaultQtyCustom = isValidNumber(defaultQtyCustom) ? defaultQtyCustom : null;// parseInt(defaultQtyCustom) || null;
        this.defaultQty = iDefaultQtyCustom;


        this.selectedCell = undefined;
        this.pricingRows.forEach(x => {
            x.resetCellHighlights();
            x.highlightOwnLegs();
        });

        this._grid.api.refreshCells({force: true});
    }

    getPmdHedgeMode(targetSide: 'calls' | 'puts') {
        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return '???';
        }

        return this.pmdHedgeMode;
    }

    canChangeUnderlying() {
        if (this.mode === 'opg') {
            return true;
        }

        return this.pmdHedgeMode !== 'existing';
    }

    canChangeExpiration() {
        if (this.mode === 'opg') {
            return true;
        }

        return this.pmdHedgeMode !== 'existing';
    }

    getHedgesCost(targetSide: 'calls' | 'puts'): number {
        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return;
        }

        const groupId = targetSide === 'calls'
            ? this.selectedCallHedge?.id
            : this.selectedPutHedge?.id;

        if (isVoid(groupId)) {
            return undefined;
        }

        const cost = this._hedgesPricingService.getHedgeGroupCost(groupId);

        return cost;
    }

    @DetectMethodChanges()
    onDefaultQtyChanged($event: DxValueChanged<number>) {
        this.callStrategies.concat(this.putStrategies).forEach(x => x.markForChanges());

        const key: OpgStateKey = this.pmdHedgeMode === 'existing'
            ? 'opg.default-qty.own'
            : 'opg.default-qty.custom';

        this.saveOpgState(key, $event.value);

        this.applyLiveIfPossible();
    }

    async copyOrders(destination: 'ets' | 'hedging-grid' | 'tos', args: GetContextMenuItemsParams) {

        this.showVerificationDialog(args)
            .then(async () => {
                if (destination === 'ets') {
                    await this.onCopyToEts(args);
                } else if (destination === 'tos') {
                    await this.copyComboToTOS(args);
                } else if (destination === 'hedging-grid') {
                    await this.copyComboToHedgingGrid(args);
                }
            })
            .catch(() => {
                // ignore
            });
    }

    showVerificationDialog(args: GetContextMenuItemsParams): Promise<void> {
        const colId = args.column.getColId();
        const is3Col = colId.startsWith('trans') || colId.startsWith('own') || colId.startsWith('out');
        const isPrice = args.column.getColDef().field === 'callPrice' || args.column.getColDef().field === 'putPrice';

        if (is3Col || isPrice) {
            return Promise.resolve();
        }


        const combo = this.makeComboItems(args);
        const config: OrdersVerificationDialogConfig = {
            orderLegs: combo.items,
            orderPrice: combo.orderPrice,
            ledgerPrice: combo.ledgerPrice,
            defaultQty: this.defaultQty
        };
        return this.orderVerificationDialog.show(config);
    }

    private applyLiveIfPossible(side?: 'calls' | 'puts') {

        const calls = this.canApplyAllStrategies('calls') && this.callsLiveMode;
        const puts = this.canApplyAllStrategies('puts') && this.putsLiveMode;

        switch (side) {
            case "calls":
                if (calls) {
                    this.applyAllStrategies('calls');
                }
                break;
            case "puts":
                if (puts) {
                    this.applyAllStrategies('puts');
                }
                break;
            default:
                if (calls) {
                    this.applyAllStrategies('calls');
                }

                if (puts) {
                    this.applyAllStrategies('puts');
                }
        }
    }


    @DetectMethodChanges({isAsync: true})
    private async onPortfoliosUpdated(payload: { portfolios: ApgPortfolio[] }) {
        const selectedCallPortfolioId = this.selectedCallsPortfolio?.id + this.selectedCallsPortfolio?.userId;
        const selectedPutPortfolioId = this.selectedPutsPortfolio?.id + this.selectedPutsPortfolio?.userId;

        const updatedCallSelected = payload.portfolios.find(x => {
            const key = x.id + x.userId;
            return key === selectedCallPortfolioId;
        });

        const updatedPutSelected = payload.portfolios.find(x => {
            const key = x.id + x.userId;
            return key === selectedPutPortfolioId;
        });

        try {
            this.isLoading = true;

            await this.initPortfolioService();

            if (updatedCallSelected) {
                this.selectedCallsPortfolio = updatedCallSelected;
            }

            if (updatedPutSelected) {
                this.selectedPutsPortfolio = updatedPutSelected;
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    }

    scrollEvent = new EventEmitter<BodyScrollEvent>();

    onBodyScrollEvent(args: BodyScrollEvent) {
        this.scrollEvent.emit(args);
    }


    private scrollToTargetSide(targetSide: "calls" | "puts") {
        if (!this.autoSizeColumns) {
            return;
        }

        const to = 0;

        if (targetSide === 'puts') {
            setTimeout(() => {
                this._grid.api.ensureColumnVisible('putOtm');
            }, to);
        } else if (targetSide === 'calls') {
            setTimeout(() => {
                this._grid.api.ensureColumnVisible('callOtm');
            }, to);
        }
    }


    private onGridScrolled(args: BodyScrollEvent) {

        if (!this.autoSizeColumns) {
            return;
        }

        const hasPricingColumns = this.pricingRows.some(x => x.getPricingColumnsAttributes().length > 0);

        if (!hasPricingColumns) {
            return;
        }

        setTimeout(() => {
            args.columnApi.getAllColumns()
                .forEach(clmn => {
                    args.columnApi.autoSizeColumn(clmn);
                });
        });
    }

    findExpirationOffset(offset: number): OptionExpirationDescriptor {
        if (!isValidNumber(offset)) {
            offset = 0;
        }

        const chain = this.optionChain;
        const expirations = chain.expirations.slice();
        const dte = offset + this.selectedExpiration.daysToExpiration;
        let targetExpirationDescriptor: OptionExpirationDescriptor;

        if (offset > 0) {
            targetExpirationDescriptor = expirations.find(x => x.daysToExpiration >= dte);
        } else if (offset < 0) {
            targetExpirationDescriptor = expirations.reverse().find(x => x.daysToExpiration <= dte);
        } else {
            targetExpirationDescriptor = this.selectedExpiration;
        }

        return targetExpirationDescriptor;
    };

    @DetectMethodChanges()
    toggleCalendarized(strategy: PricingGridStrategy) {
        strategy.calendarized = !strategy.calendarized;
        if (!strategy.calendarized) {
            strategy.strategyLegs.forEach(x => x.expirationOffset = null);
        }
        strategy.onSomethingChanged();
    }

    isExpOffsetVisible(leg: PricingGridLegDescriptor, strategy: PricingGridStrategy): 'visible'|'hidden' {
        if (leg.type === 'width') return 'hidden';
        return strategy.calendarized ? 'visible' : 'hidden';
    }

    private validateOffsetsForStrategies(validationContext: { side: string; expiration: OptionExpirationDescriptor }) {
        let callsAreGood = true;

        if (validationContext.side.toLowerCase().indexOf('call') >= 0) {
            callsAreGood = this.callStrategies.every(x => x.validateOffsets(validationContext));
        }

        let putsAreGood = true;

        if (validationContext.side.toLowerCase().indexOf('put') >= 0) {
            putsAreGood = this.putStrategies.every(x => x.validateOffsets(validationContext));
        }

        let allGood = true;

        if (validationContext.side.toLowerCase().indexOf('call') >= 0) {
            allGood = allGood && callsAreGood;
        }

        if (validationContext.side.toLowerCase().indexOf('put') >= 0) {
            allGood = allGood && putsAreGood;
        }

        return allGood;
    }
}